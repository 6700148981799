#tophat {
    padding-top: 30px;
    margin-bottom: 30px;

    .phone-wrap {
        text-align: center;
    }

    .phone {
        font-size: 24px;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 5px;

        a:hover,
        a:focus,
        a:active {
            text-decoration: none;
        }
    }
    .phone-title {
        font-size: 12px;
        font-size: .750rem;
        font-weight: 700;
    }
    .phone-desc {
        font-size: 12px;
        font-size: .750rem;        
    }
    @media (max-width: 768px) {
        .logo img {
            display: block;
            margin: 0 auto 15px auto;
            text-align: center;
        }
        .phone-wrap {
            margin-bottom: 15px;
        }
        .btn-quote {
            display: block;
        }
    }    
}
.navbar-toggle {
    .icon-bar {
        background-color: #FFF !important;
    }
}
#header {
    .navbar-default {
        border-radius: 0;
        background-color: $brand-primary;
        border-color: $brand-primary;
        margin-bottom: 0;
    }

    .navbar-nav {
        & >  li {
            & > a {
                color: #FFF;
                @include transition();
            }
            & > a:hover {
                color: #FFF;
                text-shadow: 0 1px 3px rgba(0,0,0,.3);
                background-color: darken($brand-primary, 5%);
                @include transition();
            }
            &.active {
                & > a {
                    background-color: #434445;
                }
            }
        }
    }
}
#hero {
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 500px;
    overflow: hidden;
    position: relative;

    .hero-content {
        background: -moz-linear-gradient(left, rgba(67,68,69,0.75) 0%, rgba(67,68,69,0.75) 30%, rgba(67,68,69,0) 100%);
        background: -webkit-linear-gradient(left, rgba(67,68,69,0.75) 0%,rgba(67,68,69,0.75) 30%,rgba(67,68,69,0) 100%);
        background: linear-gradient(to right, rgba(67,68,69,0.75) 0%,rgba(67,68,69,0.75) 30%,rgba(67,68,69,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf434445', endColorstr='#00434445',GradientType=1 );
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 10%;
        padding-right: 10%;
        position: absolute;
        bottom: 0;

        .hero-title {
            color: #FFF;
            font-weight: 700;
            font-size: 48px;
            font-size: 3rem;
            text-transform: uppercase;
            text-shadow: 0 1px 3px rgba(0,0,0,.3);
            letter-spacing: 2px;
        }
        .hero-sub-title {
            color: #FFF;
            font-size: 24px;
            font-size: 1.5rem;
            text-shadow: 0 1px 3px rgba(0,0,0,.3);
            max-width: 650px;
        }
    }
}