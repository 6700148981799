// Glyphicons font path
$icon-font-path: "../fonts/";

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Colors
$brand-primary: #0ca3be;
$dark-green: darken($brand-primary, 20%);
$dark-gray: #434445;

// Fonts
$prompt: 'Prompt', sans-serif;

@mixin ease-in-out($time:.3s) {
    -webkit-transition: all $time ease-in-out;
    -moz-transition: all $time ease-in-out;
    -ms-transition: all $time ease-in-out;
    -o-transition: all $time ease-in-out;
    transition: all $time ease-in-out;
}

@mixin gradient-animation( $start, $end, $transTime ){
    background-size: 100%;
    background: -moz-linear-gradient(top, $start 0%, $end 100%);
    background: -webkit-linear-gradient(top, $start 0%,$end 100%);
    background: linear-gradient(to bottom, $start 0%,$end 100%);
    position: relative;
    z-index: 100;
    &:before {
        background: -moz-linear-gradient(top, $end 0%, $end 100%);
        background: -webkit-linear-gradient(top, $end 0%, $end 100%);
        background: linear-gradient(to bottom, $end 0%, $end 100%);
        content: '';    
        display: block;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        opacity: 0;
        width: 100%;
        z-index: -100;
        transition: opacity $transTime;
    }
    &:hover {
        &:before {
            opacity: 1; 
            transition: opacity $transTime;
        }
    }
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
