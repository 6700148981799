html {
    font-size: 100%;
}
body {
    font-family: $prompt;
    color: $dark-gray;
    font-size: 16px;
    font-size: 1rem;
}

a {
    @include ease-in-out();

    &:hover,
    &:focus,
    &:active {
        @include ease-in-out();        
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}
.wrap {
    p {
        line-height: 175%;
        margin-bottom: 30px;
    }
}