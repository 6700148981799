.btn {
    &.btn-quote {
        font-size: 12px;
        font-size: .750rem;
        background: $brand-primary;
        @include gradient-animation ($brand-primary, $dark-green, .3s);
        border:1px solid #066c7f;
        color: #FFF;
        text-shadow: 0 1px 3px rgba(0,0,0,.3);
        padding-top: 10px;
        -webkit-box-shadow: inset 1px 4px 9px -6px rgba(255,255,255,1);
        -moz-box-shadow: inset 1px 4px 9px -6px rgba(255,255,255,1);
        box-shadow: inset 1px 4px 9px -6px rgba(255,255,255,1);

        .quote-line1,
        .quote-line2,
        .quote-line3 {
            display: block;
            clear: both;
            line-height: 1.1;
        }
        .quote-line-2 {
            padding-bottom: 3px;
        }
        .quote-line3 {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 18px;
            font-size: 1.125rem;
            padding-bottom: 3px;
            letter-spacing: 1px;
        }
        &:hover,
        &:focus,
        &:active {
            outline: none;
        }

        &:after {
            content: '';
            display: inline-block;
            background: url(../images/swish.png) no-repeat 0 0;
            width: 116px;
            height: 11px;
        }
    }
}