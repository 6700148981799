#footer {
    background-color: #434445;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #FFF;
    clear: both;

    a {
        color: #FFF;
    }
    #menu-footer-menu,
    .menu {
        padding: 0;
        margin: 0;
        
        & > li {
            list-style: none;
        }
    }
}
#copyright {
    background-color: $brand-primary;
    text-align: center;
    font-size: 10px;
    font-size: .625rem;
    color: #FFF;
    padding-top: 30px;
    padding-bottom: 30px;

    a {
        color: #FFF;
    }
}

.social-media {
    ul {
        margin-bottom: 0;

        & > li {
            padding: 0 1px;
        }
    }
    a {
        background-color: #505152;
        padding: 5px 8px;
        font-size: 24px;
        font-size: 1.5rem;
        text-shadow: 0 1px 3px rgba(0,0,0,.3);

        &:hover,
        &:active,
        &:focus {
            background-color: #434445;
        }
    }
}